.contacts {
  line-height: 1.75;
  background: $light-blue;

  h1 {
    text-align: center;
    margin-bottom: 60px;
  }

  h2 {
    margin-bottom: 30px;
  }

  &__info {
    padding-left: 30px;

    @include media-breakpoint-down(md) {
      padding-left: 0;
      margin: 60px 0;
    }
  }

  &__mail {
    padding-left: 30px;

    @include media-breakpoint-down(md) {
      padding-left: 0;
      margin: 60px 0;
    }

    a {
      font-weight: 300;
    }

    svg {
      margin-right: 10px;
    }
  }

  &__boxes {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-right: 30px;

    @include media-breakpoint-down(lg) {
      padding-right: 0;
    }
  }

  &__box {
    flex: 1;
    display: flex;

    a {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-radius: $border-radius-lg;
      box-shadow: $box-shadow-lg;
      border-bottom: none;

      top: 0;
      position: relative;
      transition: top 0.2s ease-in-out;

      @include media-breakpoint-down(md) {
        padding: 30px;
      }

      span {
        border-bottom: 1px solid;
      }

      &:hover {
        top: -10px;

        span {
          border-bottom: none;
        }
      }
    }

    h2 {
      line-height: 1.5;
      text-align: center;
      margin-bottom: 10px;
      padding: 0 15px;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &-contact {
      a {
        background: $light-blue;
      }

      h2 {
        color: $body-color;
      }
    }

    &-hiring {
      a {
        background: $primary;
        color: $white;
      }
    }
  }

  .line {
    width: 50px;
    height: 3px;
    background: $primary;
    display: block;
    margin: 30px 0;
  }

  .contact-person {
    font-size: 15px;
    line-height: 1.8;

    strong {
      margin: 15px 0 5px;
      display: block;
    }

    a {
      font-weight: 300;
    }
  }

  .social {
    line-height: normal;
  }
}
