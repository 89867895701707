.phone-sticker {
  position: fixed;
  right: 0;
  top: 50%;

  transition: transform 0.2s ease-in-out;
  transform: translateX(180px) translateY(-50%);
  z-index: 10;

  display: flex;
  flex-direction: column;
  background: $blue;
  box-shadow: $box-shadow;
  border: 0.55px solid rgba(255, 255, 255, 0.3);

  &.open {
    transform: translateY(-50%);
  }

  .item {
    display: flex;
    color: $white;

    &:first-child {
      .toggle,
      .content {
        padding-bottom: 15px;
      }
    }

    .toggle,
    .content {
      padding: 30px 0;
    }

    .toggle {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 24px;

        &.fa-phone {
          position: relative;
          top: 3px;
        }
      }
    }

    .content {
      width: 180px;
      padding-left: 10px;

      a {
        color: $white;
      }
    }
  }
}
