body {
  margin: 0;
  overflow-x: hidden;
  overflow-anchor: none;

  &.no-scroll {
    height: 100vh;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }
}
