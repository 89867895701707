.about {
  h2 {
    margin: 40px 0;
  }

  h3 {
    margin-bottom: 30px;
  }

  h5 {
    margin-bottom: 70px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  .container {
    padding-top: 20px;
    padding-right: 20px;
  }

  &-content {
    padding-left: 40px;
    position: relative;

    max-width: 70%;
    margin: auto;

    @include media-breakpoint-down(md) {
      margin: 60px 0;
      max-width: none;
    }

    &::before {
      content: "";
      width: 28px;
      height: 3px;
      background: $primary;
      position: absolute;
      left: 0;
      top: 10px;
    }
  }
}
