.team {
  &-members {
    margin-top: 60px;

    .row > [class^="col-"] {
      padding-bottom: 30px;
    }

    &__item {
      font-weight: 300;
      color: $body-color;
      border-bottom: none;

      display: flex;
      flex-direction: column;

      &:hover {
        span {
          background: $primary;
          color: $white;
        }
      }

      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;

        &:hover {
          cursor: pointer;

          img {
            min-width: 105%;
            max-height: 105%;
            transition:
              min-width 0.5s,
              max-height 0.5s;
          }

          .modal-name {
            color: $blue;
          }
        }

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .item-image {
          min-width: 120px;
          max-width: 120px;
          height: 120px;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 15px;
          position: relative;

          img {
            min-width: 100%;
            max-height: 100%;
            position: absolute;
            inset: 0;
            object-fit: cover;
            object-position: top;
            left: 50%;
            transform: translateX(-50%);
          }

          &:hover {
            img {
              min-width: 105%;
              max-height: 105%;
              transition:
                min-width 0.5s,
                max-height 0.5s;
            }
          }
        }
      }
    }

    h2,
    h4 {
      text-align: center;
    }

    .link {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
}

.modal {
  &-button {
    border: none;
    color: $dark-blue;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-dialog {
    padding: 5px;
    width: 60%;
    max-width: 800px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &-img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
  }

  &-name {
    text-align: center;
    margin-top: 10px;
  }

  &-role {
    text-align: center;
  }

  &-left {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);

    @include media-breakpoint-down(xl) {
      align-items: center;
      width: 100%;
    }
  }

  &-content {
    display: flex;
    gap: 30px;
    flex-direction: row;
    border: none;

    @include media-breakpoint-down(xl) {
      flex-direction: column;
      width: 100%;
    }
  }

  &-body {
    padding: 25px;
  }

  .short-desc {
    display: flex;
    align-items: center;
    text-align: left;
    flex: calc(100% / (2 / 3));

    @include media-breakpoint-down(xl) {
      width: 100%;
    }
  }

  .close {
    margin-bottom: 10px;
    border: none;
    background: white;
    float: right;
  }
}
