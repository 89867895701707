.jumbotron {
  background-image: linear-gradient(
    172deg,
    #0d258d 0%,
    #2f55b8 84%,
    #2f55b8 84%
  );
  height: 600px;
  color: $white;

  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;
  padding: 90px 0 100px;
  border-radius: 0;
  margin-bottom: 0;

  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding-top: 60px;
    padding-bottom: 40px;
    height: calc(100vh - 45px);
  }

  @include media-breakpoint-down(sm) {
    padding-right: 60px;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    background: url("../../assets/img/jumbotron-figure.svg") no-repeat;
    background-size: contain;
    opacity: 0.1;
    width: 948.75px;
    height: 715px;
    z-index: 1;

    transform: none;
    transform-origin: center center;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &::after {
    top: -100%;
    right: calc(100% - 500px);
    animation: jumbotronAnimationLeft 15s linear infinite 15s;
  }

  &::before {
    top: -50%;
    left: calc(100% - 500px);
    animation: jumbotronAnimation 30s linear infinite;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .title,
  .subtitle {
    @include media-breakpoint-up(lg) {
      max-width: 70%;
    }
  }

  .title {
    font-family: $font-family-secondary;
    font-size: 46px;
    line-height: 1.28;
    margin-bottom: 40px;

    span {
      font-weight: 600;
    }

    @include media-breakpoint-down(md) {
      font-size: 32px;

      br {
        display: none;
      }
    }
  }

  .subtitle {
    font-family: $font-family-secondary;
    font-size: 18px;
    padding-left: 70px;
    position: relative;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    span {
      font-weight: 600;
    }

    &::before {
      content: "";
      width: 55px;
      height: 3px;
      background: $white;
      position: absolute;
      left: 0;
      top: 12px;
    }
  }

  .social {
    a {
      color: $white;
    }
  }

  &-link {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(md) {
      bottom: 40px;
      z-index: 2;
    }

    a {
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1.5px;
    }
  }
}
