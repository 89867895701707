.conferences {
  .container {
    @include media-breakpoint-up(xl) {
      max-width: 1600px;
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  .desktop-carousel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    gap: 10px;
  }

  .mobile-carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .nav-buttons {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }

  .carousel-button {
    &-next,
    &-prev {
      height: 40px;
      width: 40px;
      border: none;
      background-color: $light-blue;
      color: $dark-blue;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      transition-property: background-color, border-color, color;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      display: flex;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

      &:hover,
      &:active {
        background-color: $primary;
        border-color: $primary;
        color: $white;
      }
    }
  }

  .conference-content {
    background: $light-blue;
    border-radius: $border-radius-lg;
    padding: 40px;
    margin: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: $box-shadow-lg;

    @include media-breakpoint-down(xl) {
      margin-left: 10px;
      margin-right: 10px;
      padding: 20px 20px 0;
    }

    h2 {
      padding-bottom: 20px;
      text-align: center;
    }

    p {
      margin-bottom: 0;
    }

    .desktop-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      gap: 50px;
      align-items: center;

      .split-left,
      .split-right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .split-left img {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        object-position: center;
        display: block;
      }

      .split-right {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .info-table {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;

          .divider {
            width: 3px;
            background-color: $dark-blue;
            height: 50px;
          }

          .table-cell {
            max-width: 100px;
            text-align: center;
          }
        }
      }
    }

    .mobile-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      align-items: center;
      overflow: hidden;
      gap: 10px;

      img {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
        object-position: center;
        display: block;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      .info-table {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        gap: 10px;
        width: 100%;

        .divider {
          width: 3px;
          background-color: $dark-blue;
          height: 50px;
        }
        .table-cell {
          flex: 1;
          display: flex;
          text-align: center;
          justify-content: center;
          font-size: 14px;

          @include media-breakpoint-down(sm) {
            max-width: 100px;
          }
        }
      }

      .grey-out {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100px;
        background: linear-gradient(
          to top,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        );
        pointer-events: none;
        transition: opacity 0.3s ease;
      }

      .expand-btn {
        background: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        z-index: 2;

        .button-img {
          width: 50px;
          transition: transform 0.3s ease;
        }

        &.open img {
          transform: rotate(180deg);
        }
      }
    }
  }
}
