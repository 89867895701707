@use "sass:math";

.image {
  position: relative;
  cursor: pointer;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div(277, 370) * 100%;
  }

  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}
