footer {
  padding: 60px 0;
  background: $blue;
  color: $white;

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 14px;

    .logo {
      margin-bottom: 20px;
      position: relative;
      width: 50px;
      height: 40px;

      img {
        width: 50px;
        height: 40px;

        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -20px;

        transform-origin: center;
        transform: none;
        transition: transform 5s ease;

        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        &:hover {
          animation-name: logoRotation;
        }
      }
    }
  }
}
