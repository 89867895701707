.links {
  background: $light-blue;
  padding-bottom: 150px;

  .carousel-button {
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    display: flex;
    &-next,
    &-prev {
      height: 40px;
      width: 40px;
      border: none;
      margin: 0 15px 0 15px;
      color: #11205e;
      background-color: $white;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      transition-property: background-color, border-color, color;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      display: flex;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

      &:hover,
      &:active {
        background-color: $primary;
        border-color: $primary;
        color: $white;
      }
    }
  }

  &-item {
    width: 100%;
    margin: 15px;

    display: flex;
    border-bottom: none;

    padding: 40px;
    background: $white;
    color: $body-color;
    transition:
      background-color 0.2s ease-in-out,
      top 0.2s ease-in-out;

    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-lg;

    position: relative;
    top: 0;

    @media (max-width: 1200px) {
      padding: 40px 20px;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    &.hidden {
      opacity: 0;
    }

    &:hover {
      background: darken($light-blue, 5%);
      top: -10px;

      svg {
        opacity: 1;
      }
    }

    .image {
      min-width: 180px;

      @include media-breakpoint-down(lg) {
        max-height: 150px;
      }

      img {
        width: 100%;
      }
    }

    .content {
      padding-left: 20px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      @include media-breakpoint-down(lg) {
        padding-left: 0;
        padding-top: 20px;
      }

      h3 {
        height: 100%;
      }
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;

      span + span {
        margin-left: 10px;
      }

      span {
        font-size: 10px;
        text-transform: uppercase;
        padding: 2px 5px;
        border: 1px solid $body-color;
        color: $body-color;
        border-radius: 3px;
        font-weight: 600;
      }
    }

    svg {
      position: absolute;
      top: 30px;
      right: 30px;
      opacity: 0;
      font-size: 14px;
      transition: opacity 0.2s ease;

      @include media-breakpoint-down(lg) {
        top: 15px;
        right: 15px;
      }
    }
  }

  .carousel-inner {
    margin-top: 40px;
  }

  .carousel-item {
    width: 100%;
    transition: 0.3s ease;
  }

  @media (max-width: 768px) {
    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(100%);
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
      transform: translateX(-100%);
    }
  }

  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    display: flex;
  }

  @media (min-width: 768px) {
    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(50%);
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
      transform: translateX(-50%);
    }
  }

  .carousel-inner .carousel-item-end,
  .carousel-inner .carousel-item-start {
    transform: translateX(0);
  }
}
