.banner {
  background: #ffd300;
  display: flex;
  justify-content: center;
  padding: 10px;

  a {
    color: #005dc2;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 900;

    span {
      font-size: 22px;
      margin-left: 10px;
      position: absolute;
      left: 100%;
    }
  }
}
