@keyframes jumbotronAnimationLeft {
  0% {
    top: -100%;
    transform: rotate(0deg);
  }
  100% {
    top: 100%;
    transform: rotate(-360deg);
  }
}

@keyframes jumbotronAnimation {
  0% {
    top: -50%;
    transform: rotate(0deg);
  }
  100% {
    top: 100%;
    transform: rotate(-360deg);
  }
}

@keyframes logoRotation {
  from {
    transform: none;
  }

  to {
    transform: rotate(360deg);
  }
}
