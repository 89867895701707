.navbar-toggler {
  padding: 0;

  &:focus {
    outline: none;
  }

  .burger {
    width: 40px;
    height: 21px;
    position: relative;

    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    &-line {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $white;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      transform-origin: left center;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 9px;
        transform-origin: center;
      }

      &:nth-child(4) {
        top: 18px;
      }
    }

    &.cross {
      .burger-line {
        &:nth-child(1) {
          top: 18px;
          width: 0;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 18px;
          width: 0;
          left: 50%;
        }
      }
    }
  }
}
