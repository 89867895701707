@font-face {
  font-family: "Archia";
  src: url("../fonts/archia-thin-webfont.ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("../fonts/archia-light-webfont.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("../fonts/archia-regular-webfont.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("../fonts/archia-medium-webfont.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("../fonts/archia-semibold-webfont.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("../fonts/archia-bold-webfont.ttf");
  font-weight: 700;
  font-style: normal;
}
