.social {
  display: flex;

  @include media-breakpoint-down(md) {
    margin-bottom: 80px;
  }

  a + a {
    margin-left: 25px;
  }

  a {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border: none;
    opacity: 1;

    transition: opacity 0.2s ease-in-out;

    &:hover {
      i,
      svg,
      &::after {
        top: -5px;
      }
    }

    i,
    svg,
    &::after {
      top: 0;
      transition: top 0.2s ease-in-out;
    }

    i,
    svg {
      position: relative;
      font-size: 22px;
    }

    &.social-facebook-f {
      padding-bottom: 3px;
    }

    &.social-cb {
      position: relative;
      font-size: 20px;
      width: 25px;

      &::after {
        content: "cb";
        position: absolute;
        left: 0;
      }
    }

    &.social-youtube {
      margin-bottom: -1px;

      i {
        font-size: 20px;
      }
    }
  }
}
