.navbar {
  --bs-navbar-active-color: $light-blue;
  --bs-navbar-hover-color: $light-blue;

  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100%;
  height: 87px;

  display: flex;
  align-items: flex-end;

  @include media-breakpoint-down(md) {
    height: 60px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }

  .container {
    max-width: calc(1290px + #{$grid-gutter-width} * 2);
  }

  &-collapse {
    transition: none;
  }

  &-brand {
    width: 160px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: left;
    border: none;
    padding: 0;

    @include media-breakpoint-down(md) {
      height: 40px;
      width: auto;
    }

    img {
      height: 100%;
    }
  }

  ul {
    list-style: none;
    margin: 0 0 0 auto;
    padding: 0;
    display: flex;

    li {
      padding: 0 15px;

      &:last-child {
        padding-right: 0;
      }
    }

    a {
      color: $white;
      font-weight: 700;
      border-bottom: none;
      font-family: $font-family-secondary;
      font-size: 18px;
      letter-spacing: 0.18px;
    }

    // language switcher
    &.language-switcher {
      margin: 0 0 0 60px;

      @include media-breakpoint-down(sm) {
        flex-direction: row;
        margin: 60px 0 0;
      }

      li {
        padding: 0 10px;
      }

      a {
        font-weight: 400;
        font-size: 16px;
        padding: 0 !important;

        &.active,
        &:hover {
          border-bottom: 1px solid;
        }

        &.active {
          font-weight: 700;
        }
      }
    }
  }

  // mobile menu
  @include media-breakpoint-down(md) {
    &-brand,
    &-toggler {
      position: relative;
      z-index: 2;
      --bs-navbar-toggler-focus-width: 0;
    }

    &-collapse {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      padding: 60px 15px 15px;

      background: linear-gradient(172deg, #0d258d 0%, #2f55b8 84%, #2f55b8 84%);

      transition:
        height 0s,
        opacity 0.3s;
      opacity: 0;

      &.show {
        opacity: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      ul {
        margin-left: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        li {
          padding: 0;
        }
      }
    }
  }
}
