.projects {
  background: $light-blue;

  h1 {
    padding-bottom: 30px;
    text-align: center;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 95px;
  }

  .container {
    position: relative;
    height: 100%;

    &::after {
      content: "";
      width: calc((100% - 30px) * 0.6);
      top: 110px;
      height: calc(100% + 60px);
      position: absolute;
      z-index: 1;
      right: 15px;
      bottom: 0;
      background: $blue;
      border-radius: $border-radius-lg;
      box-shadow: $box-shadow-lg;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &-browser {
    position: relative;
    z-index: 2;
    padding-top: 120px;

    @include media-breakpoint-down(md) {
      padding-top: 95px;
    }

    > div {
      display: flex;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }
  }

  &-list {
    width: 40%;
    position: relative;
    padding-bottom: 20px;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-bottom: 0;
    }

    .list-group {
      @include media-breakpoint-down(md) {
        padding: 0 60px;
        margin-bottom: 30px;
      }

      &-item {
        border: none;
        display: flex;
        padding: 0 40px;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(md) {
          padding: 0;
          width: auto;
          flex-direction: column;
          cursor: default;
        }

        &:not(:last-child) {
          margin-bottom: 30px;

          @include media-breakpoint-down(md) {
            margin-bottom: 0;
          }
        }

        &::before {
          content: "";
          width: 28px;
          height: 3px;
          background: $primary;
          position: absolute;
          left: 0;
          top: 12px;

          opacity: 0;
          transition: opacity 0.25s ease-in-out;
        }

        &.active {
          margin-top: 0;

          .index {
            color: $primary;
          }

          &::before {
            opacity: 1;
          }
        }

        &:not(.active):hover {
          .index {
            color: $primary;
          }
        }

        .index {
          color: $light-gray;
          font-size: 20px;
          font-weight: 900;
          transition: color 0.25s ease-in-out;

          @include media-breakpoint-down(md) {
            padding-left: 40px;
          }
        }

        .content {
          padding: 3px 10px;

          @include media-breakpoint-down(md) {
            padding: 3px 0;
          }
        }
      }
    }
  }

  &-detail {
    width: 60%;
    padding: 0 60px;
    color: $white;

    @include media-breakpoint-down(md) {
      width: 100%;
      background: $blue;
      border-radius: $border-radius-lg;
      box-shadow: $box-shadow-lg;
      padding: 40px 30px;
    }

    h2 {
      margin-bottom: 20px;
    }

    .btn {
      margin-top: 30px;
    }
  }

  .carousel-item {
    padding: 0px 15px;
    transition: 0.3s ease;
  }
  .carousel-item-content {
    background: $primary;
    color: $white;
    border-radius: $border-radius-lg;
    padding: 20px;
  }
  .carousel-button {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &-next,
    &-prev {
      height: 40px;
      width: 50px;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid $pale-blue;
      background-color: transparent;
      color: $dark-blue;
    }
  }

  .current-project {
    border: none;
    display: flex;
    padding: 0 20px;
    position: relative;
    width: 100%;
    height: 110px;

    .index {
      color: $blue;
      font-size: 20px;
      font-weight: 900;
      transition: color 0.25s ease-in-out;
    }

    .content {
      padding: 3px 0px;
    }
  }
}
